<template>
  <div class="markets-investor-table-container">
    <cp-table
      ref="cpTable"
      get-data-action="brokerDealerInvestors/getBrokerDealerInvestorsList"
      :fields="activeTableFields"
      :data-parser="parseData"
      :search-value="searchQueryParam"
      :filters="filters"
      :url-params="urlParams"
      rows-clickable
      table-title="Investors"
      no-local-sorting
      @tableDataUpdated="onTableDataUpdated"
      @rowClicked="onRowClicked"
    >
      <template
        slot="countryCode"
        slot-scope="{ rowData }"
      >
        <cp-country-name :country-code="rowData.item.countryCode" />
      </template>
      <template
        slot="verificationStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom"
          :class="kycStatuses[rowData.item.verificationStatus]"
        >
          {{ calculateStatus(rowData.item) }}
        </b-badge>
        <b-badge
          v-if="rowData.item.verificationSubStatus === 'enhance-requested'"
          :id="`${rowData.index}-is-enhance-required`"
          class="align-text-bottom badge-grey ml-1"
        >
          {{ rowData.item.verificationSubStatus }}
        </b-badge>
      </template>
      <template
        slot="suitabilityStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom"
          :class="suitabilityStatuses[rowData.item.suitabilityStatus]"
        >
          {{ rowData.item.suitabilityStatus.replace('-', ' ') }}
        </b-badge>
      </template>
      <template
        slot="suitabilityEuStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom"
          :class="suitabilityStatuses[rowData.item.suitabilityEuStatus]"
        >
          {{ rowData.item.suitabilityEuStatus.replace('-', ' ') }}
        </b-badge>
      </template>
      <template
        slot="accreditationStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom"
          :class="accreditationStatuses[rowData.item.accreditationStatus]"
        >
          {{ rowData.item.accreditationStatus.replace('-', ' ') }}
        </b-badge>
      </template>
      <template
        slot="accountStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom"
          :class="accountStatuses[rowData.item.accountStatus]"
        >
          {{ rowData.item.accountStatus.replace('-', ' ') }}
        </b-badge>
      </template>
      <template
        slot="accountEuStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          class="align-text-bottom"
          :class="accountStatuses[rowData.item.accountEuStatus]"
        >
          {{ rowData.item.accountEuStatus.replace('-', ' ') }}
        </b-badge>
      </template>
      <template
        slot="createDate"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.createDate | dateFilterWOTz }}
        </span>
      </template>
      <template
        slot="assignedAttorneyOperatorId"
        slot-scope="{ rowData }"
      >
        <b-select
          :disabled="!canChangeAssignee"
          injected-classes="m-0"
          :value="rowData.item.assignedAttorneyOperatorId"
          :options="attorneys"
          @input="attorneyAssigneeChange(rowData.item.investorId, $event, rowData.item.assignedAttorneyOperatorId)"
        />
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <cp-button
          size="sm"
          variant="info"
          class="btn btn-primary btn-sm"
          @click="viewInvestorDetails(rowData.item)"
        >
          {{ $t('common.button.view') }}
        </cp-button>
      </template>
    </cp-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CpCountryName from '~/components/common/country-name';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpTimeConverter from '~/mixins/time-converter';
import { kycStatuses, tableFields, suitabilityStatuses, accountStatuses, accreditationStatuses } from './options';

export default {
  name: 'CpBrokerDealerTable',
  components: {
    CpCountryName,
    CpTable,
    CpButton,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      downloading: false,
      kycStatuses,
      suitabilityStatuses,
      accountStatuses,
      tableFields,
      accreditationStatuses,
      totalTableData: [],
      searchQueryParam: this.$route.query.search || undefined,
      isEmptyList: false,
    };
  },
  computed: {
    ...mapGetters('global', ['issuers']),
    ...mapState('operators', ['operatorsList']),
    ...mapGetters('currentOperator', ['operatorData']),
    canChangeAssignee() {
      return this.operatorData.authorizations.includes('securitize-markets');
    },
    attorneys() {
      const currentOperator = this.operatorsList
        .find(operator => operator.name.toUpperCase() === this.operatorData.name.toUpperCase());
      if (!currentOperator) return [];

      const operators = this.operatorsList
        .filter(operator => (operator.name !== currentOperator.name && operator.authorizations.includes('securitize-markets')))
        .reduce((acc, { id, name }) => ([...acc, { value: id, text: name }]), [])
        .sort((a, b) => {
          const nameA = a.text.toUpperCase();
          const nameB = b.text.toUpperCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;

          return 0;
        });

      operators.splice(0, 0, { value: currentOperator.id, text: currentOperator.name });
      operators.splice(1, 0, { value: 'none', text: 'None' });
      operators.splice(2, 0, { value: 'compliance-team', text: 'Compliance Team' });
      operators.splice(3, 0, { value: 'operations-team', text: 'Operations Team' });

      return operators;
    },
    activeTableFields() {
      return this.tableFields;
    },
    urlParams() {
      const checkItemArray = item => (Array.isArray(item) ? item : [item]);
      const transformItems = {
        issuers: checkItemArray,
        countries: checkItemArray,
        states: checkItemArray,
        investorType: checkItemArray,
        verificationStatus: checkItemArray,
        suitability: checkItemArray,
        accreditationStatus: checkItemArray,
        accountStatus: checkItemArray,
        accountEuStatus: checkItemArray,
        assignedAttorneyOperatorId: checkItemArray,
      };

      return Object.keys(this.$route.query)
        .reduce((accum, key) => (transformItems[key] && this.$route.query[key]
          ? { ...accum, [key]: transformItems[key](this.$route.query[key]) } : accum), {});
    },
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['getBrokerDealerInvestorsList', 'getInvestorsListCsv']),
    onTableDataUpdated({ items, totalItems }) {
      this.totalTableData = items;
      this.isEmptyList = totalItems === 0;
    },
    calculateStatus(item) {
      const blocked = item.verificationStatus === 'rejected'
          && item.verificationSubStatus === 'blocked'
          && 'rejected[blocked]';
      const documentsExpired = item.verificationStatus === 'verified'
          && item.verificationSubStatus === 'documents-expired'
          && 'verified[documents-expired]';
      const expired = item.verificationStatus === 'verified'
          && item.verificationSubStatus === 'expired'
          && 'verified[expired]';
      const enhanceVerified = item.verificationStatus === 'verified'
          && item.verificationLevel === 'enhanced'
          && 'enhance verified';

      return blocked || documentsExpired || expired || enhanceVerified || item.verificationStatus.replace('-', ' ');
    },
    parseData(data) {
      return data.map(item => ({
        ...item,
        id: item.investorId,
        suitabilityStatus: item.suitabilityStatus || 'none',
        suitabilityEuStatus: item.suitabilityEuStatus || 'none',
        accreditation: item.accreditation || 'none',
        brokerDealerVerificationStatus: item.brokerDealerVerificationStatus || 'none',
        accountStatus: item.accountStatus || 'none',
        accountEuStatus: item.accountEuStatus || 'none',
        assignedAttorneyOperatorId: item.assignedAttorneyOperatorId || 'none',
      }));
    },
    onRowClicked({ data: item, e }) {
      if (e.target.className.includes('multiselect_') || e.target.className.includes('btn-primary')) {
        return e.preventDefault();
      }
      this.$emit('onViewElem', item, e);
    },
    attorneyAssigneeChange(investorId, operatorId, assignedAttorneyOperatorId) {
      if (operatorId === assignedAttorneyOperatorId) return;
      this.$emit('assigneeAttorney', { investorId, operatorId });
    },
    viewInvestorDetails(item) {
      this.$emit('onViewInvestorDetails', {
        investorId: item.investorId,
        investorStatuses: {
          suitabilityStatus: item.suitabilityStatus,
          suitabilityEuStatus: item.suitabilityEuStatus,
          accountStatus: item.accountStatus,
          accountEuStatus: item.accountEuStatus,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .headers-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  .input-group{
    width: auto;
  }
</style>


<style lang="scss">
  .markets-investor-table-container {
    .table-responsive {
      color: white;
    }
    .badge-grey, .badge-orange, .badge-yellow, .badge-green, .badge-red {
      text-transform: capitalize;
      padding: 0.5em 1em;
      border-radius: 2px;
      color: white;
    }
    .badge-grey {
      background-color: #dee0e2;
      -webkit-box-shadow: 0 0 0 1px #dee0e2 inset;
      box-shadow: 0 0 0 1px #dee0e2 inset;
    }
    .badge-orange {
      background-color: #F5A01D;
      -webkit-box-shadow: 0 0 0 1px #F5A01D inset;
      box-shadow: 0 0 0 1px #F5A01D inset;
    }
    .badge-yellow {
      background-color: #ffd950;
      -webkit-box-shadow: 0 0 0 1px #ffd950 inset;
      box-shadow: 0 0 0 1px #ffd950 inset;
    }
    .badge-green {
      background-color: #07bc77;
      -webkit-box-shadow: 0 0 0 1px #07bc77 inset;
      box-shadow: 0 0 0 1px #07bc77 inset;
    }
    .badge-red {
      background-color: #f7321f;
      -webkit-box-shadow: 0 0 0 1px #f7321f inset;
      box-shadow: 0 0 0 1px #f7321f inset;
    }
  }
</style>
