var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"markets-investor-table-container"},[_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"brokerDealerInvestors/getBrokerDealerInvestorsList","fields":_vm.activeTableFields,"data-parser":_vm.parseData,"search-value":_vm.searchQueryParam,"filters":_vm.filters,"url-params":_vm.urlParams,"rows-clickable":"","table-title":"Investors","no-local-sorting":""},on:{"tableDataUpdated":_vm.onTableDataUpdated,"rowClicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"countryCode",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-country-name',{attrs:{"country-code":rowData.item.countryCode}})]}},{key:"verificationStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom",class:_vm.kycStatuses[rowData.item.verificationStatus]},[_vm._v(" "+_vm._s(_vm.calculateStatus(rowData.item))+" ")]),(rowData.item.verificationSubStatus === 'enhance-requested')?_c('b-badge',{staticClass:"align-text-bottom badge-grey ml-1",attrs:{"id":((rowData.index) + "-is-enhance-required")}},[_vm._v(" "+_vm._s(rowData.item.verificationSubStatus)+" ")]):_vm._e()]}},{key:"suitabilityStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom",class:_vm.suitabilityStatuses[rowData.item.suitabilityStatus]},[_vm._v(" "+_vm._s(rowData.item.suitabilityStatus.replace('-', ' '))+" ")])]}},{key:"suitabilityEuStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom",class:_vm.suitabilityStatuses[rowData.item.suitabilityEuStatus]},[_vm._v(" "+_vm._s(rowData.item.suitabilityEuStatus.replace('-', ' '))+" ")])]}},{key:"accreditationStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom",class:_vm.accreditationStatuses[rowData.item.accreditationStatus]},[_vm._v(" "+_vm._s(rowData.item.accreditationStatus.replace('-', ' '))+" ")])]}},{key:"accountStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom",class:_vm.accountStatuses[rowData.item.accountStatus]},[_vm._v(" "+_vm._s(rowData.item.accountStatus.replace('-', ' '))+" ")])]}},{key:"accountEuStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom",class:_vm.accountStatuses[rowData.item.accountEuStatus]},[_vm._v(" "+_vm._s(rowData.item.accountEuStatus.replace('-', ' '))+" ")])]}},{key:"createDate",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFilterWOTz")(rowData.item.createDate))+" ")])]}},{key:"assignedAttorneyOperatorId",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-select',{attrs:{"disabled":!_vm.canChangeAssignee,"injected-classes":"m-0","value":rowData.item.assignedAttorneyOperatorId,"options":_vm.attorneys},on:{"input":function($event){return _vm.attorneyAssigneeChange(rowData.item.investorId, $event, rowData.item.assignedAttorneyOperatorId)}}})]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-button',{staticClass:"btn btn-primary btn-sm",attrs:{"size":"sm","variant":"info"},on:{"click":function($event){return _vm.viewInvestorDetails(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.button.view'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }