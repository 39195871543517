<template>
  <cp-general-modal
    ref="investorDetailsModal"
    :hide-footer="true"
    size="lg"
  >
    <template
      slot="modal-header"
    >
      <span class="title">Investor details</span>
      <div
        class="d-flex flex-row"
      >
        <span class="assignee p-2">Assignee: </span>
        <cp-input
          :value="attorneyName"
          name="Assignee"
          class="mr-2"
          :disabled="true"
        />
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="hideModal"
      >
        ×
      </button>
    </template>
    <b-row>
      <b-col
        v-if="isLoading"
      >
        <div
          class="spinner-container mx-auto text-center mt-2 modal-size"
        >
          <b-spinner
            class="spinner"
          />
        </div>
      </b-col>
      <b-col
        v-if="!isLoading"
        md="12"
      >
        <div class="mb-4 bg-light full-width">
          <div :class="['col-md-6', 'd-flex', 'investor-type-field']">
            <span class="mr-1">Investor Type: </span>
            <strong
              class="investor-type-value"
            >
              {{ investor.investorType }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr v-if="isEntity">
                  <td>Name:</td>
                  <td>
                    <strong>
                      {{ investor.name }}
                    </strong>
                  </td>
                </tr><!--Name-->
                <tr v-if="isEntity">
                  <td>Entity DBA:</td>
                  <td>
                    <strong>
                      {{ investor.entityDba }}
                    </strong>
                  </td>
                </tr><!--entityDba-->
                <tr v-if="isIndividual">
                  <td>First Name:</td>
                  <td>
                    <strong>
                      {{ investor.firstName }}
                    </strong>
                  </td>
                </tr><!--firstName-->
                <tr v-if="isIndividual">
                  <td>Middle Name:</td>
                  <td>
                    <strong>
                      {{ investor.middleName }}
                    </strong>
                  </td>
                </tr><!--middleName-->
                <tr v-if="isIndividual">
                  <td>Last Name:</td>
                  <td>
                    <strong>
                      {{ investor.lastName }}
                    </strong>
                  </td>
                </tr><!--lastName-->
                <tr>
                  <td>Email:</td>
                  <td>
                    <strong>
                      {{ investor.email }}
                    </strong>
                  </td>
                </tr><!--email-->
                <tr v-if="isEntity && investor.entityType">
                  <td>Type:</td>
                  <td>
                    <strong>
                      {{ $t(`securitizeId.entityType["${investor.entityType}"]`) || investor.entityType }}
                    </strong>
                  </td>
                </tr><!--entityType-->
                <tr v-if="isIndividual">
                  <td>Phone Prefix:</td>
                  <td>
                    <strong>
                      {{ investor.phonePrefix }}
                    </strong>
                  </td>
                </tr><!--phonePrefix-->
                <tr v-if="isIndividual">
                  <td>Phone:</td>
                  <td>
                    <strong>
                      {{ investor.phoneNumber }}
                    </strong>
                  </td>
                </tr><!--phoneNumber-->
                <tr v-if="isIndividual">
                  <td>Birthdate</td>
                  <td>
                    <strong>
                      <template v-if="investor.birthDay">
                        {{ investor.birthDay | dateFilterWOTz }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </strong>
                  </td>
                </tr><!--birthdate-->
                <tr v-if="isIndividual">
                  <td>Gender:</td>
                  <td>
                    <strong>
                      {{ investor.gender }}
                    </strong>
                  </td>
                </tr><!--gender-->
                <tr>
                  <td>
                    Country:
                  </td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('countryCode') }}
                    </strong>
                  </td>
                </tr><!--countryCode-->
                <tr v-if="investor.countryCode === 'US'">
                  <td>
                    State:
                  </td>
                  <td>
                    <strong>
                      {{ generalDetailState() }}
                    </strong>
                  </td>
                </tr>state
                <tr>
                  <td>City:</td>
                  <td>
                    <strong>
                      {{ investor.city }}
                    </strong>
                  </td>
                </tr><!--city-->
                <tr>
                  <td>Postal Code:</td>
                  <td>
                    <strong>
                      {{ investor.postalCode }}
                    </strong>
                  </td>
                </tr><!--postalCode-->
                <tr>
                  <td>Creation Date:</td>
                  <td>
                    <strong>
                      {{ investor.creationDate | dateFilter }}
                    </strong>
                  </td>
                </tr><!--creationDate-->
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr v-if="isEntity">
                  <td>Tax ID:</td>
                  <td>
                    <strong>
                      {{ investor.taxId1 }}
                    </strong>
                  </td>
                </tr><!--taxId-->
                <tr v-if="isEntity">
                  <td>Tax Country (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode1') }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode-->
                <tr>
                  <td>Street Name:</td>
                  <td>
                    <strong>
                      {{ investor.streetName }}
                    </strong>
                  </td>
                </tr><!--streetName-->
                <tr>
                  <td>Street Number:</td>
                  <td>
                    <strong>
                      {{ investor.streetNumber }}
                    </strong>
                  </td>
                </tr><!--streetNumber-->
                <tr>
                  <td>Additional Info:</td>
                  <td>
                    <strong>
                      {{ investor.addressAdditionalInfo }}
                    </strong>
                  </td>
                </tr><!--addressAdditionalInfo-->
                <tr v-if="isIndividual">
                  <td>Tax ID:</td>
                  <td>
                    <strong>
                      {{ investor.taxId1 }}
                    </strong>
                  </td>
                </tr><!--taxId1-->
                <tr v-if="isIndividual">
                  <td>Tax Country 1 (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode1') }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode1-->
                <tr v-if="isIndividual">
                  <td>Tax ID 2:</td>
                  <td>
                    <strong>
                      {{ investor.taxId2 }}
                    </strong>
                  </td>
                </tr><!--taxId2-->
                <tr v-if="isIndividual">
                  <td>Tax Country 2 (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode2') }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode2-->
                <tr v-if="isIndividual">
                  <td>Tax ID 3:</td>
                  <td>
                    <strong>
                      {{ investor.taxId3 }}
                    </strong>
                  </td>
                </tr><!--taxId3-->
                <tr v-if="isIndividual">
                  <td>Tax Country 3 (FATCA):</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('taxCountryCode3') }}
                    </strong>
                  </td>
                </tr><!--taxCountryCode3-->
                <tr v-if="isIndividual">
                  <td>Identity Document Number:</td>
                  <td>
                    <strong>
                      {{ investor.identityDocumentNumber }}
                    </strong>
                  </td>
                </tr><!--Identity Document Number-->
                <tr v-if="isIndividual">
                  <td>Country of Birth:</td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('countryOfBirth') }}
                    </strong>
                  </td>
                </tr><!--countryOfBirth-->
                <tr v-if="isIndividual">
                  <td>City of Birth:</td>
                  <td>
                    <strong>
                      {{ investor.cityOfBirth }}
                    </strong>
                  </td>
                </tr><!--cityOfBirth-->
                <tr v-if="isEntity">
                  <td>Incorporation date:</td>
                  <td>
                    <strong>
                      {{ investor.incorporationDate | dateFilter }}
                    </strong>
                  </td>
                </tr><!--incorporationDate-->
              </tbody>
            </table>
          </div>
        </div>
        <hr>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column content">
            <div class="col-md-12">
              <span class="select-title">{{ 'Suitability answers' }}</span>
              <table
                v-if="doHaveSuitabilityAnswers"
                class="table table-striped table-borderless mb-0 mt-3"
              >
                <tbody>
                  <tr>
                    <td>
                      <strong>Investment experience in years:</strong>
                    </td>
                  </tr>
                  <tr>
                    <div class="d-flex flex-row justify-content-sm-around pt-1 pb-1">
                      <div
                        v-for="(value, name, i) in investor.suitability.investmentExperienceInYears"
                        :key="name+i"
                        class="d-flex flex-column align-items-center"
                      >
                        <div>{{ formatCamelCaseString(name) }}:</div>
                        <div class="pt-1 font-weight-bold">
                          {{ value }}
                        </div>
                      </div>
                    </div>
                  </tr><!--investmentExperienceInYears-->
                  <tr>
                    <td>
                      <strong>Investment frequency:</strong>
                    </td>
                  </tr>
                  <tr>
                    <div class="d-flex flex-row justify-content-sm-around pt-1 pb-1">
                      <div
                        v-for="(value, name, i) in investor.suitability.investmentFrequency"
                        :key="name+i"
                        style="padding: 0 4rem 0 1rem;"
                      >
                        <div>{{ formatCamelCaseString(name) }}:</div>
                        <div class="pt-1 font-weight-bold text-capitalize">
                          {{ value }}
                        </div>
                      </div>
                    </div>
                  </tr><!--investmentFrequency-->
                  <tr>
                    <td>
                      <strong>Investor information:</strong>
                    </td>
                  </tr>
                  <tr>
                    <div class="d-flex flex-row pt-1 pb-1">
                      <div
                        v-for="(value, name, i) in investor.suitability.investorInformation"
                        :key="i"
                        style="margin: 0 1rem 0 1.5rem;"
                      >
                        <div>{{ formatCamelCaseString(name) }}: </div>
                        <div class="pt-1 font-weight-bold text-capitalize">
                          {{ booleanToYesNo(value) }}
                        </div>
                      </div>
                    </div>
                  </tr><!--investorInformation-->
                  <tr>
                    <td>
                      <strong>Risk tolerance affirmation:</strong>
                    </td>
                  </tr>
                  <tr>
                    <div class="d-flex flex-row justify-content-sm-around pt-1 pb-1">
                      <div
                        v-for="(value, name, i) in investor.suitability.riskToleranceAffirmation"
                        :key="i"
                        style="margin: 0 .5rem 0 1rem;"
                      >
                        <div>{{ formatCamelCaseString(name) }}:</div>
                        <div class="pt-1 font-weight-bold text-capitalize">
                          {{ booleanToYesNo(value) }}
                        </div>
                      </div>
                    </div>
                  </tr><!--riskToleranceAffirmation-->
                  <tr>
                    <td>
                      <strong>Confirmed affirmations:</strong>
                    </td>
                  </tr>
                  <tr>
                    <ul>
                      <li
                        v-for="(value, i) in investor.suitability.confirmedAffirmations"
                        :key="i"
                        class="mb-1"
                      >
                        <strong>{{ value }}</strong>
                      </li>
                    </ul>
                  </tr><!--confirmedAffirmations-->
                </tbody>
              </table>
              <div
                v-if="!doHaveSuitabilityAnswers"
                class="d-flex flex-row ml-3"
              >
                No suitability answers provided
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="col-md-12">
            <cp-comments
              custom-height="200px"
              title="US suitability comments:"
              :comments-array="suitabilityComment()"
              :is-editable="isEditComment"
              :value="suitabilityComment().join('\n')"
              @input="changeComment"
            />
            <cp-button
              size="lg"
              variant="info"
              class="btn-xs comment-edit-btn"
              @click="editComment"
            >
              {{ isEditComment ? 'Save' : 'Edit' }}
            </cp-button>
          </div>
          <div class="col-md-12">
            <cp-comments
              custom-height="200px"
              title="EU Suitability comments:"
              :comments-array="suitabilityEuComment()"
              :is-editable="isEditEuComment"
              :value="suitabilityEuComment().join('\n')"
              @input="changeEuComment"
            />
            <cp-button
              size="lg"
              variant="info"
              class="btn-xs comment-edit-btn"
              @click="editEuComment"
            >
              {{ isEditEuComment ? 'Save' : 'Edit' }}
            </cp-button>
          </div>
        </div>
        <hr>
        <div class="d-flex flex-row justify-content-around mt-4">
          <cp-button
            size="md"
            variant="info"
            :disabled="suitabilityStatusVerified"
            @click="approveSuitability"
          >
            <span
              class="font-500"
            >Approve US Suitability</span>
          </cp-button>
          <cp-button
            size="md"
            variant="info"
            :disabled="accountStatusApproved"
            @click="approveAccount"
          >
            <span
              class="font-500"
            >Approve US Account</span>
          </cp-button>
          <cp-button
            size="md"
            variant="info"
            :disabled="suitabilityEuStatusVerified"
            @click="approveEuSuitability"
          >
            <span
              class="font-500"
            >Approve EU Suitability</span>
          </cp-button>
          <cp-button
            size="md"
            variant="info"
            :disabled="accountEuStatusApproved"
            @click="approveEuAccount"
          >
            <span
              class="font-500"
            >Approve EU Account</span>
          </cp-button>
        </div>
      </b-col>
    </b-row>
  </cp-general-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { countriesList as countries } from '~/pages/securitize-id/components/options';
import states from '~/utilities/us-states-list';

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpComments from '~/components/common/standalone-components/cp-comments';
import { CpInput } from '@/components/common/standalone-components/inputs';

export default {
  name: 'InvestorDetailsModal',
  components: {
    CpGeneralModal,
    CpButton,
    CpInput,
    CpComments,
  },
  props: {
    investor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      states,
      countries,
      newComment: null,
      newEuComment: null,
      isEditComment: false,
      isEditEuComment: false,
    };
  },
  computed: {
    ...mapState('operators', ['operatorsList']),
    attorneys() {
      // TODO: remove filter after bugfix https://securitize.atlassian.net/browse/ISR2-247
      return this.operatorsList
        .filter(operator => operator.authorizations.includes('securitize-markets'))
        .map(({ id, name }) => ({ id, name }));
    },
    isLoading() {
      return !(this.investor && this.investor.investorId);
    },
    doHaveSuitabilityAnswers() {
      return !!(this.investor && this.investor.suitability && this.investor.suitability.investmentExperienceInYears);
    },
    attorneyName() {
      const { name = 'None' } = this.attorneys.find(attorney => attorney.id === this.investor.assignedAttorneyOperatorId) || {};
      return name;
    },
    suitabilityStatusVerified() {
      return this.investor.suitabilityStatus === 'verified';
    },
    suitabilityEuStatusVerified() {
      return this.investor.suitabilityEuStatus === 'verified';
    },
    accountStatusApproved() {
      return this.investor.accountStatus === 'approved';
    },
    accountEuStatusApproved() {
      return this.investor.accountEuStatus === 'approved';
    },
    isEntity() {
      return this.investor.investorType === 'entity';
    },
    isIndividual() {
      return this.investor.investorType === 'individual';
    },
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['updateSuitabilityStatus', 'updateEuSuitabilityStatus']),
    getCurrentCountryName(field) {
      const country = this.countries.find(item => item.value === this.investor[field]);
      return country ? country.text : null;
    },
    formatCamelCaseString(header) {
      const regex = new RegExp(/([A-Z]{2,})/);
      return header.split(regex).map(char => (
              regex.test(char)
                ? char
                : char.replace(/([A-Z])/g, ' $1').toLowerCase().replace(/^./, (str, i) => (i === 0 ? str.toUpperCase() : str))
      )).join(' ');
    },
    booleanToYesNo(val) {
      if (typeof val === 'boolean') return val ? 'Yes' : 'No';
      return val;
    },
    suitabilityComment() {
      return this.investor && this.investor.suitability && this.investor.suitability.comment ? this.investor.suitability.comment.split('\n') : [];
    },
    suitabilityEuComment() {
      return this.investor && this.investor.suitabilityEu && this.investor.suitabilityEu.comment ? this.investor.suitabilityEu.comment.split('\n') : [];
    },
    stateOptions() {
      return Object.keys(this.states).map(val => ({ text: this.states[val], value: val }));
    },
    generalDetailState() {
      const currentState = this.stateOptions().find(el => el.value === this.investor.state);
      return currentState ? currentState.text : null;
    },
    hideModal() {
      this.$refs.investorDetailsModal.hide();
    },
    approveSuitability() {
      if (this.isEditComment) {
        this.isEditComment = !this.isEditComment;
        this.investor.suitability.comment = this.newComment;
        this.newComment = null;
      }
      this.investor.suitabilityStatus = 'verified';
      this.$emit('onApproveSuitability', this.investor);
    },
    approveEuSuitability() {
      if (this.isEditEuComment) {
        this.isEditEuComment = !this.isEditEuComment;
        this.investor.suitabilityEu.comment = this.newEuComment;
        this.newEuComment = null;
      }
      this.investor.suitabilityEuStatus = 'verified';
      this.$emit('onApproveEuSuitability', this.investor);
    },
    approveAccount() {
      this.investor.accountStatus = 'approved';
      this.$emit('onApproveAccount', this.investor.investorId);
    },
    approveEuAccount() {
      this.investor.accountEuStatus = 'approved';
      this.$emit('onApproveEuAccount', this.investor.investorId);
    },
    async addComment() {
      if (this.isEditComment) this.isEditComment = !this.isEditComment;
      this.investor.suitability.comment = this.newComment;

      await this.updateSuitabilityStatus({
        investorId: this.investor.investorId,
        comment: this.newComment,
      });
      this.newComment = null;
      this.isEditComment = false;
    },
    async addEuComment() {
      if (this.isEditEuComment) this.isEditEuComment = !this.isEditEuComment;
      this.investor.suitabilityEu.comment = this.newEuComment;

      await this.updateEuSuitabilityStatus({
        investorId: this.investor.investorId,
        comment: this.newEuComment,
      });
      this.newEuComment = null;
      this.isEditEuComment = false;
    },
    editComment() {
      this.isEditComment = !this.isEditComment;
      if (!this.isEditComment) this.addComment();
    },
    editEuComment() {
      this.isEditEuComment = !this.isEditEuComment;
      if (!this.isEditEuComment) this.addEuComment();
    },
    changeComment(val) {
      this.newComment = val;
    },
    changeEuComment(val) {
      this.newEuComment = val;
    },
  },
};
</script>

<style scoped lang="scss">
  .font-500 {
    font-weight: 500;
  }

  .modal-header {
    padding: 10px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #617D8D;
  }

  .assignee {
    font-size: 14px;
  }

  .content {
    flex-grow: 0.5;
    margin-bottom: 20px;
  }

  .select-title {
    color: #0A1828;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .comment-edit-btn {
    position: absolute;
    top: 2.3rem;
    right: 1.6rem;
  }
</style>
