<template>
  <div>
    <div class="filters-box">
      <div class="filter">
        <market-multi-select
          ref="issuers"
          :options="issuersOptions"
          placeholder="Issuers"
          name="issuers"
          :searchable="true"
          :default-selected="appliedFilters.issuers"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="countries"
          :options="countriesList"
          placeholder="Country"
          name="countries"
          :searchable="true"
          :default-selected="appliedFilters.countries"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div
        v-if="doDisplayStateFilter()"
        class="filter"
      >
        <market-multi-select
          ref="states"
          :options="statesList"
          placeholder="State"
          name="states"
          :default-selected="appliedFilters.states || []"
          :searchable="true"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="investorType"
          :options="[
            { text: 'Individual', value: 'individual' },
            { text: 'Entity', value: 'entity' },
          ]"
          placeholder="Investor type"
          name="investorType"
          :default-selected="appliedFilters.investorType"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="verificationStatus"
          :options="kyc.statuses"
          placeholder="Identity checks"
          name="verificationStatus"
          :default-selected="appliedFilters.verificationStatus"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="suitability"
          :options="suitability.statuses"
          placeholder="Suitability"
          name="suitability"
          :default-selected="appliedFilters.suitability"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="accreditationStatus"
          :options="accreditation.statuses"
          placeholder="Accreditation"
          name="accreditationStatus"
          :default-selected="appliedFilters.accreditationStatus"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div class="filter">
        <market-multi-select
          ref="accountStatus"
          :options="account.statuses"
          placeholder="Account"
          name="accountStatus"
          :default-selected="appliedFilters.accountStatus"
          @filtersChanged="onFiltersChange"
        />
      </div>
      <div
        class="filter"
      >
        <market-multi-select
          ref="assignedAttorneyOperatorId"
          :options="attorneys"
          placeholder="Assignee"
          name="assignedAttorneyOperatorId"
          :default-selected="appliedFilters.assignedAttorneyOperatorId"
          @filtersChanged="onFiltersChange"
        />
      </div>
    </div>
    <!-- Active filters labels -->
    <div class="d-flex align-items-center mt-4 flex-wrap">
      <div
        v-for="filter in activeFilters"
        :key="filter.value"
        class="selected-filter"
      >
        <span class="mb-0">{{ getFilterType(filter) }}{{ $t(filter.i18nKey) || filter.text }}</span>
        <i
          class="ion ion-ios-close ml-2"
          @click="() => removeFilter(filter)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import states from '~/utilities/us-states-list';
import { account, brokerDealerVerification, countriesList, filterLists, suitability, accreditation, kyc } from './options';
import MarketMultiSelect from '@/pages/broker-dealer/investors/components/market-multiselect';

const statesList = Object.keys(states).map(key => ({ value: key, text: states[key] }));

export default {
  name: 'BrokerDealerInvestorFilter',
  components: { MarketMultiSelect },
  data() {
    return {
      filterLists,
      registrationSourceList: [],
      countriesList,
      statesList,
      account,
      suitability,
      brokerDealerVerification,
      accreditation,
      kyc,
      appliedFilters: {},
      activeFilters: [],
      investorTypeOptions: [
        { text: 'Individual', value: 'individual' },
        { text: 'Entity', value: 'entity' },
      ],
    };
  },
  computed: {
    ...mapState('issuerList', ['allSystemIssuersList']),
    ...mapState('operators', ['operatorsList']),
    issuersOptions() {
      return (this.allSystemIssuersList.length ? this.allSystemIssuersList : []).map(issuer => ({ text: issuer.name, value: issuer.issuerId }));
    },
    attorneys() {
      // TODO: remove filter after bugfix https://securitize.atlassian.net/browse/ISR2-247
      return this.operatorsList
        .filter(operator => operator.authorizations.includes('securitize-markets'))
        .reduce((acc, { id, name }) => ([...acc, { value: id, text: name }]), [
          { value: 'none', text: 'None' },
          { value: 'compliance-team', text: 'Compliance Team' },
          { value: 'operations-team', text: 'Operations Team' },
        ]);
    },
  },
  watch: {
    allSystemIssuersList() { this.updateFilters(); },
    attorneys() { this.updateFilters(); },
  },
  methods: {
    updateFilters() {
      this.appliedFilters = this.getFiltersFromQuery();
      this.activeFilters = this.getActiveFilters();
      const filterValues = Object.keys(this.appliedFilters)
        .reduce((accum, key) => ({ ...accum, [key]: this.appliedFilters[key].map(({ value }) => value) }), {});
      this.$emit('filtersApplied', filterValues);
    },
    onFiltersChange({ name: filterKey, filters }) {
      this.appliedFilters[filterKey] = filters;
      if (filterKey === 'countries' && this.appliedFilters.states && !filters.find(({ value }) => value === 'US')) {
        delete this.appliedFilters.states;
      }
      this.activeFilters = Object.keys(this.appliedFilters)
        .reduce((accum, key) => ([...accum, ...this.appliedFilters[key].map(filter => ({ ...filter, key }))]), []);
      const filterValues = Object.keys(this.appliedFilters)
        .reduce((accum, key) => ({ ...accum, [key]: this.appliedFilters[key].map(({ value }) => value) }), {});
      this.$emit('filtersApplied', filterValues);

      const { search } = this.$route.query;
      if (search) filterValues.search = search;
      this.$router.replace({ query: filterValues });
    },
    removeFilter(filter) {
      this.$refs[filter.key].deleteFilter(filter.value);
    },
    getFilterType(filter) {
      if (['suitability', 'accountStatus', 'brokerDealerVerificationStatus', 'accreditationStatus', 'verificationStatus'].includes(filter.key)) {
        return `${this.$t(`brokerDealer.investorDetails.filters.${filter.key}`)}: `;
      }
    },
    getFilterValues() {
      return Object.keys(this.appliedFilters)
        .reduce((accum, key) => (this.appliedFilters[key].length
          ? { ...accum, [key]: this.appliedFilters[key].map(({ value }) => value) }
          : accum), {});
    },
    getFiltersFromQuery() {
      const values = this.$route.query || {};
      const filterOptions = {
        issuers: this.issuersOptions,
        countries: this.countriesList,
        states: this.statesList,
        investorType: this.investorTypeOptions,
        verificationStatus: this.filterLists.kycStatus,
        verificationErrors: this.filterLists.verificationErrors,
        suitability: this.suitability.statuses,
        accreditationStatus: this.accreditation.statuses,
        accountStatus: this.account.statuses,
        assignedAttorneyOperatorId: this.attorneys,
      };
      return Object.keys(values)
        .reduce((accum, key) => {
          if (!filterOptions[key]) {
            return accum;
          }
          const selectedOptions = Array.isArray(values[key]) ? values[key] : [values[key]];
          const filter = selectedOptions.map((value) => {
            const item = filterOptions[key].find(option => option.value === value);
            return { ...item, applied: true };
          });
          return { ...accum, [key]: filter };
        }, {});
    },
    getActiveFilters() {
      return Object.keys(this.appliedFilters)
        .reduce((accum, key) => ([...accum, ...this.appliedFilters[key].map(filter => ({ ...filter, key }))]), []);
    },
    doDisplayStateFilter() {
      return !!(this.appliedFilters.countries || []).find(country => country.value === 'US');
    },
  },
};
</script>

<style lang="scss" scoped>
  .filters-box {
    display: flex;
    flex-wrap: wrap;
  }

  .filter {
    margin: .1rem;
    width: 19%;
  }

  .selected-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    border-radius: 50px;
    background-color: rgba(97, 125, 141, 0.1);
    margin: 0 1em 1em 0;
    font-size: 14px;
    font-weight: 500;
    color: #697e8d;
    text-transform: capitalize;
    i {
      font-size: 24px;
      cursor: pointer;
    }
  }
</style>
