<template>
  <div>
    <div v-show="isReady && isAttornyReady">
      <div>
        <div class="header-block mb-4">
          <h4 class="font-weight-bold mb-0">
            <i class="ion ion-ios-people" />
            Market investors
          </h4>
        </div>
      </div>
      <broker-dealer-investor-filter />
      <cp-broker-dealer-table
        :key="updateTable"
        @onViewElem="toViewPage"
        @onViewInvestorDetails="openInvestorDetailsModal"
        @assigneeAttorney="assigneeAttorney"
      />
    </div>
    <div
      v-show="!isReady || !isAttornyReady"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        Loading Investor Data...
      </div>
    </div>
    <investor-details-modal
      ref="investorDetailsModal"
      :investor="selectedInvestor"
      @onApproveSuitability="approveSuitability"
      @onApproveEuSuitability="approveEuSuitability"
      @onApproveAccount="approveAccount"
      @onApproveEuAccount="approveEuAccount"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CpBrokerDealerTable from './components/table';
import routeNames from '@/utilities/routes';
import BrokerDealerInvestorFilter from '@/pages/broker-dealer/investors/components/filter';
import InvestorDetailsModal from './components/investor-details-modal.vue';

export default {
  name: 'BrokerDealerInvestors',
  metaInfo: {
    title: 'Markets',
  },
  components: {
    BrokerDealerInvestorFilter,
    CpBrokerDealerTable,
    InvestorDetailsModal,
  },
  data() {
    return {
      isReady: false,
      isAttornyReady: false,
      selectedInvestor: {},
      updateTable: false,
    };
  },
  created() {
    this.getAllIssuersList().finally(() => { this.isReady = true; });
    this.fetchOperators({
      params: { 'operator-authorization': 'securitize-markets' },
    }).finally(() => { this.isAttornyReady = true; });
  },
  methods: {
    ...mapActions({ getAllIssuersList: 'issuerList/getAllSystemIssuers' }),
    ...mapActions('operators', ['fetchOperators']),
    ...mapActions(
      'securitizeId',
      [
        'updateSecuritizeIdAssignedAttorneyOperator',
        'getSecuritizeIdInvestorByInvestorId',
      ],
    ),
    ...mapActions(
      'brokerDealerInvestors',
      [
        'updateAccountStatus',
        'updateEuAccountStatus',
        'updateSuitabilityStatus',
        'updateEuSuitabilityStatus',
        'getBrokerDealerInvestorId',
      ],
    ),
    toViewPage({ investorId }, e) {
      if (investorId) {
        const routeName = routeNames.brokerDealerInvestorDetails({ investorId });
        const query = this.$route.fullPath.split('?')[1];
        if (e.ctrlKey || e.metaKey) {
          const route = this.$router.resolve(routeName);
          const path = query ? `${route.href}?${query}` : route.href;
          window.open(path, '_blank');
          return;
        }
        const path = query ? `${routeName}?${query}` : routeName;
        this.$router.push(path);
      }
    },
    async openInvestorDetailsModal({ investorId, investorStatuses }) {
      const isSameInvestor = this.selectedInvestor.investorId === investorId;
      if (!isSameInvestor) this.selectedInvestor = {};

      this.$refs.investorDetailsModal.$refs.investorDetailsModal.show();

      if (!this.selectedInvestor.investorId || !isSameInvestor) {
        const { data } = await this.getBrokerDealerInvestorId({ investorId });
        this.selectedInvestor = { ...data, ...investorStatuses };
        this.selectedInvestor.suitability = JSON.parse(data.suitability.answers);
        this.selectedInvestor.suitability.comment = data.suitability.comment;
        this.$refs.investorDetailsModal.$refs.investorDetailsModal.show();
      }
    },
    async assigneeAttorney({ investorId, operatorId }) {
      await this.updateSecuritizeIdAssignedAttorneyOperator({ investorId, operatorId });
      if (this.selectedInvestor && this.selectedInvestor.investorId === investorId) {
        this.selectedInvestor.assignedAttorneyOperatorId = operatorId;
      }
    },
    approveAccount(investorId) {
      this.updateAccountStatus({ investorId, status: 'approved' })
      .then(() => { this.updateTable = !this.updateTable; });
    },
    approveEuAccount(investorId) {
      this.updateEuAccountStatus({ investorId, status: 'approved' })
      .then(() => { this.updateTable = !this.updateTable; });
    },
    approveSuitability(investor) {
      this.updateSuitabilityStatus({
        investorId: investor.investorId,
        status: 'verified',
        comment: investor.suitability.comment,
      })
      .then(() => { this.updateTable = !this.updateTable; });
    },
    approveEuSuitability(investor) {
      this.updateEuSuitabilityStatus({
        investorId: investor.investorId,
        status: 'verified',
        comment: investor.suitabilityEu.comment,
      })
      .then(() => { this.updateTable = !this.updateTable; });
    },
  },
};
</script>
